import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Divider from "../components/Divider"
import MinorParagraph from "../components/MinorParagraph"
import Bio from "../components/bio"
import MarkdownComponent from "../components/MarkdownComponent"

interface Props {

}

const Services = ({ }: Props) => {
	return (
		<Layout>
			<SEO title="Services" />
			<MarkdownComponent slug="services" />
			<Divider />
			<footer>
				<MinorParagraph>
					<MarkdownComponent slug="services-footer" />
				</MinorParagraph>
				<Bio />
			</footer>
		</Layout>
	)
}

export default Services
